import {
    Box,
    Button,
    Card,
    CardActionArea,
    CardContent, Dialog, DialogActions,
    DialogContent, DialogContentText,
    DialogTitle,
    Grid,
    IconButton, Menu, MenuItem,
    Typography
} from "@mui/material";
import WorkIcon from "@mui/icons-material/Work";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import React, {useEffect, useState} from "react";
import {
    Configure,
    Hits,
    InstantSearch,
    Pagination,
    RefinementList,
    SearchBox, Stats
} from "react-instantsearch";
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import Header from "../components/Header";
import {logoutFirebase, insertClickedJob, auth, db} from "../config/firebase";
import {collection, query, where, getDocs} from "firebase/firestore";
import { useUser } from "../hooks/useUser";
import {useNavigate} from "react-router-dom";

const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
    server: {
        apiKey: "DhMBeY2Q20EQabJ8b6Q05TpqhzAWB7oA",
        nodes: [
            {
                host: "db.unpostedjobs.com",
                port: 443,
                protocol: "https"
            }
        ],
        cacheSearchResultsForSeconds: 60,
    },
    additionalSearchParameters: {
        query_by: "title, company",
    },
});

interface Job {
    id: string;
    title: string;
    url: string;
}

const HomePage = () => {
    const [clickedJobs, setClickedJobs] = useState<Set<string>>(new Set());
    const [openModal, setOpenModal] = useState(false);
    const [clickedJob, setClickedJob] = useState<Job | null>(null);

    const [hitsCount, setHitsCount] = useState(0);
    // const [sortOrder, setSortOrder] = useState("relevance");

    const { user, loading } = useUser();
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    // @ts-ignore
    const handleAccountClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleAccountClose = () => {
        setAnchorEl(null);
    };

    const handleSignOut = async () => {
        try {
            logoutFirebase(); // Sign out
            navigate("/")
        } catch (error) {
            console.error('Error signing out', error);
        }
    };

    useEffect(() => {
        if (!loading) {
            const fetchClickedJobs = async () => {
                const q = query(collection(db, "clickedJobs"), where("userID", "==", auth.currentUser?.uid || ""));
                const querySnapshot = await getDocs(q);
                const jobIDs = new Set<string>();
                querySnapshot.forEach((doc) => {
                    jobIDs.add(doc.data().jobID);
                });
                setClickedJobs(jobIDs);
            };

            fetchClickedJobs();
        }
    }, [loading, user]);

    // Function to handle job click
    const handleJobClick = (job: Job) => {
        setClickedJob(job);
        setOpenModal(true);
    };

    // Function to handle modal close with user response
    const handleModalClose = async (applied: boolean) => {
        setOpenModal(false);
        if (applied && clickedJob) {
            await insertClickedJob(clickedJob.id, auth.currentUser?.uid || ''); // Insert clicked job
            setClickedJobs(new Set([...clickedJobs, clickedJob.id])); // Update clicked jobs state
        }
        setClickedJob(null); // Reset clicked job state
    };


    // @ts-ignore
    const renderHit = (clickedJobs) => ({ hit }) => {
        const isClicked = clickedJobs.has(hit.id);

        return (
            <Card sx={{ marginBottom: 2, backgroundColor: isClicked ? '#c0c0c0' : '#fff' }}>
                <CardActionArea onClick={() => handleJobClick(hit)} href={hit.url} target={"_blank"}>
                    <Grid container spacing={2}>
                        <Grid item xs={4} sm={3} md={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {hit.logo_url ? (
                                <img
                                    src={hit.logo_url !== "" ? hit.logo_url : '../logo.svg'}
                                    alt={`${hit.company} logo`}
                                    style={{
                                        maxWidth: '80%',
                                        maxHeight: '100px',
                                    }}
                                />
                            ) : (
                                <WorkIcon style={{ fontSize: 60, color: '#ddd', margin: '0 auto' }} /> // Centers the icon horizontally
                            )}
                        </Grid>
                        <Grid item xs={8} sm={9} md={10}>
                            <CardContent>
                                <Typography variant="h5" component="div">
                                    {hit.title}
                                </Typography>
                                <Typography variant="body2" style={{
                                    display: '-webkit-box',
                                    WebkitLineClamp: 3,
                                    WebkitBoxOrient: 'vertical',
                                    overflow: 'hidden'
                                }}>
                                    {`${hit.company}`}
                                </Typography>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                    {hit.location ? `${hit.location.substring(0, 50)}${hit.location.length > 50 ? '...' : ''}` : ''}
                                </Typography>
                                <Typography variant="body2" style={{
                                    display: '-webkit-box',
                                    WebkitLineClamp: 3,
                                    WebkitBoxOrient: 'vertical',
                                    overflow: 'hidden'
                                }}>
                                    {hit.description}
                                </Typography>
                            </CardContent>
                        </Grid>
                    </Grid>
                </CardActionArea>
            </Card>
        );
    };

    return (
        <div>
            <Header />
            <Box sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                padding: '30px',
            }}>
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={handleAccountClick}
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleAccountClose}
                >
                    <MenuItem onClick={() => { navigate('/profile'); handleAccountClose(); }}>
                        Profile
                    </MenuItem>
                    <MenuItem onClick={handleSignOut}>
                        Sign Out
                    </MenuItem>
                </Menu>
            </Box>
            <Box sx={{height: 'h:1', width: 'w:1', padding: '50px'}}>
                <InstantSearch
                    searchClient={typesenseInstantsearchAdapter.searchClient}
                    indexName="jobs"
                    routing
                >
                    <Configure hitsPerPage={10}/>
                    <Grid container spacing={2}>
                        <Grid item xs={3} />
                        <Grid item xs={7} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <SearchBox
                                placeholder="Search for jobs..."
                                style={{
                                    width: '80%',
                                    maxWidth: '800px',
                                    height: '50px',
                                }}
                            />
                        </Grid>
                        <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            <Stats />
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant='h5'>Company Name</Typography>
                            <RefinementList
                                attribute='company'
                                searchable={true}
                                style={{width: '100%'}}
                            />
                            <Typography variant='h5'>Remote</Typography>
                            <RefinementList
                                attribute='remote'
                                searchable={false}
                                style={{width: '100%'}}
                            />
                            <Typography variant='h5'>Industry</Typography>
                            <RefinementList
                                attribute='industry'
                                searchable={true}
                                style={{width: '100%'}}
                            />
                            {/*<Typography variant='h5'>Country</Typography>*/}
                            {/*<RefinementList*/}
                            {/*    attribute='country'*/}
                            {/*    searchable={true}*/}
                            {/*    style={{width: '100%'}}*/}
                            {/*/>*/}
                            {/*<Typography variant='h5'>State</Typography>*/}
                            {/*<RefinementList*/}
                            {/*    attribute='states'*/}
                            {/*    searchable={true}*/}
                            {/*    style={{width: '100%'}}*/}
                            {/*/>*/}
                            {/*<Typography variant='h5'>City</Typography>*/}
                            {/*<RefinementList*/}
                            {/*    attribute='cities'*/}
                            {/*    searchable={true}*/}
                            {/*    style={{width: '100%'}}*/}
                            {/*/>*/}
                        </Grid>
                        <Grid item xs={9}>
                            <Hits hitComponent={renderHit(clickedJobs)}/>
                        </Grid>
                        <Grid item xs={4}/>
                        <Grid item xs={8} justifyContent="center" alignItems="center">
                            <Pagination/>
                        </Grid>
                    </Grid>
                </InstantSearch>
                <Dialog open={openModal} onClose={() => handleModalClose(false)}>
                    <DialogTitle>{"Did you apply for this job?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Let us know if you applied for the job to improve your recommendations.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => handleModalClose(false)}>No</Button>
                        <Button onClick={() => handleModalClose(true)} autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </div>
    );
}
export default HomePage;